<template>
  <v-card>
    <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Cadastrar uma cotação
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
    <v-container class="form">
      <v-form>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <v-card-text
                class="pt-0 pl-0 pb-1 pr-0"
              >
                Modalidade
              </v-card-text>
              <v-select
                single-line
                outlined
                dense
                v-model="modality.modalityId"
                :items="listModalities"
                disabled
              />
            </v-col>

            <v-col cols="12" class="pb-0 pt-0">
              <v-card-text
                class="pt-0 pl-0 pb-1 pr-0"
              >
                Valor
              </v-card-text>

               <v-currency-field
                single-line
                outlined
                dense
                v-model="modality.value"
                label="Valor do prêmio"
                prefix="R$"
                :error-messages="valueErrors"
                @input="$v.modality.value.$touch()"
                @blur="$v.modality.value.$touch()"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            type="submit"
            :color="variables.colorPrimary"
            @click.prevent="handlerAlterarLimite"
            class="br-btn"
            dark
            :loading="loadingBtn"
          >Alterar</v-btn>
        </v-card-actions>
      </v-form>
    </v-container>

    <Modal
      :activeModal="isActiveModal"
      :widthModal="450"
      @closeModal="closeModalConfirmarAlteracao"
    >
      <ConfirmarAlteracao 
        @closeModal="closeModalConfirmarAlteracao"
        @confirmarAlteracao="confirmarAlteracao"
      />
    </Modal>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
// import axios from 'axios'
import { required, minValue } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'

// import config from '@/core/config'
import variables from '@/assets/styles/helpers/_variables.scss'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import validacao from './validacao'

export default {
  name: "CadastroContato",
  directives: {mask},
  mixins: [validationMixin],
  components: {
    // searchUfCity: () => import('@/views/components/searchUfCity')
    Modal: () => import('@/views/components/modal'),
    ConfirmarAlteracao: () => import('./formConfirmarAlteracao')
  },

  props: {
    configModaliade: {
      type: Array,
      default: () => []
    },


    activeModal: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isAddress: false,
    isDocument: false,
    isMemo: false,
    cepValidate: false,
    loading: false,
    palpite: '',
    config: {
      locale: 'pt-BR',
      prefix: '',
      suffix: '',
      length: 100,
      precision: 0
    },
    isActiveModal: false,
    loadingBtn: false
  }),

  watch: {
    'activeModal' (val) {
      if (!val) this.clearForm()
    }
  },

  mounted () {
    if (!this.listaItens.length) {
      this.getModalities()
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('cotacaoDinamica', ['dataConfigLimites', 'modality']),
    ...mapGetters('modalities', {
      listaItens: 'listaItens'
    }),
    ...validacao,
    variables: () => variables,

    valueErrors() {
      const errors = []
      if (!this.$v.modality.value.$dirty) return errors
      !this.$v.modality.value.minValue && errors.push('Valor mínimo é de R$ 50.000,00')
      return errors
    },

    listModalities () {
      // const arryModalidade = [ ...this.dataConfigLimites.modalities
      //                                                           .map(item => item.modalityId)
      //                                                           .filter(item => item !== this.modality.modalityId) ]
      // return this.listaItens.filter(item => !arryModalidade.includes(item.id)).map(item => ({
      //   text: item.name,
      //   value: item.id
      // }))

      return  this.listaItens.filter(item => item.id === this.modality.modalityId).map(item => ({
        text: item.name,
        value: item.id
      }))
    }
  },

  methods: {
    ...mapActions('cotacaoDinamica', ['addCotacao', 'clearData', 'editConfigLimites']),
    ...mapActions('modalities', {
      getModalities: 'getListData'
    }),

    submit () { 
      // this.addCotacao(this.modality)


      this.closeModal()
      // this.$emit('registrarCotacao', this.palpite)
    },

    handlerAlterarLimite () {
      if (this.$v.modality.$invalid) { 
        this.$v.modality.$touch()
        return false 
      }

      this.isActiveModal = true
    },

    confirmarAlteracao () {
      if (this.$v.modality.$invalid) { 
        this.$v.modality.$touch()
        return false 
      }

      this.isActiveModal = false
      this.loadingBtn = true
      this.editConfigLimites({ modalities: [ this.modality ]})
        .then(() => {
          successSnackbar('Limite alterado com sucesso!')
          this.closeModal()
        })
        .catch(e => errorSnackbar(e.error))
        .finally(() => this.loadingBtn = false)
    },

    closeModal () {
      this.$emit('closeModal', false)
    },

    closeModalConfirmarAlteracao () {
      this.isActiveModal = false
    },

    clearForm () {
      this.$v.data.$reset()
      this.clearData()
    }
  },
  
  validations () {
    return  {
      modality: {
        value: { required, minValue: minValue(50000)}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form::v-deep {
   .modal-cadastro-form-col-imput {
      padding-top: 0;
      padding-bottom: 0;
    }
   
   .v-text-field.v-text-field--enclosed .v-text-field__details {
     padding: 0
   }
  }

  .list {
    padding-left: 0;
    &-item {
      display: inline-block;
      list-style: none;
      cursor: pointer;
      color: #5076ff;

      &.active {
        color: #9797ad;
      }
    }
  }
</style>